// import React from 'react';
import React, { useState } from "react";
import { IoFolderOpen } from "react-icons/io5";
import { BsArrowUpRight } from "react-icons/bs";
import { SiRedux } from "react-icons/si";

// icons
import {
  FaHtml5,
  FaCss3,
  FaJs,
  FaReact,
  FaFigma,
  FaGitAlt,
  FaGithub,
} from "react-icons/fa";

import {
  SiNextdotjs,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiAdobeindesign,
  SiTailwindcss,
  SiTypescript,
  SiVisualstudiocode,
} from "react-icons/si";

//  data
const aboutData = [
  {
    title: "skills",
    info: [
      {
        title: "Web Development",
        icons: [
          <FaHtml5 />,
          <FaCss3 />,
          <FaJs />,
          <SiTypescript />,
          <FaReact />,
          <SiNextdotjs />,
          <SiRedux />,
          <SiTailwindcss />,
        ],
      },
      {
        title: "UI/UX Design",
        icons: [
          <FaFigma />,
          <SiAdobephotoshop />,
          <SiAdobeillustrator />,
          <SiAdobeindesign />,
        ],
      },
      {
        title: "Tools",
        icons: [<FaGitAlt />, <FaGithub />, <SiVisualstudiocode />],
      },
    ],
  },

  {
    title: "experience",
    info: [
      {
        id: 1,
        role: "React Js Developer - Freelance",
        title:
          "Developed and  improved the usability of several projects,  implementing reusable components and hooks with React.js and Next Js. Implemented global state using Redux to enhance application scalability. Integrated Strapi and Firebase as back-end solutions. Utilized Jest and E2E unit testing.",
        stage: "2024",
      },
      {
        id: 1,
        role: "Front-End Developer - Freelance",
        title:
          "I designed and developed an Ecommerce using Next JS 14, Sanity, clerk, Stripe, Api Rest. with many functionalities such as authentication, payment method and multiple pages. at the same time I have used Figma, Material ui and Framer motion. to give the application a nice design and interactivity",
        stage: "2024",
      },
      {
        id: 2,
        role: "Front-End Developer -Junior - Espai",
        title:
          "Developed an E-commerce for smart watches using Html, css, javascript, sass and jQuery with several pages and a shopping cart to be able to add and delete products at the same time I used APIs such as fetch and local storage. The app is full-responsive for all devices",
        stage: "2023",
      },
    ],
  },
  {
    id: 4,
    title: "credentials",
    info: [
      {
        title: "Javascript & JQuery - Escola Espai - ",
        year: "2024",
        link: "https://espai.es/certificates/06674403XDise%C3%B1oGr%C3%A1ficoCreaci%C3%B3nSitiosWeb.pdf",
        see: (
          <BsArrowUpRight className="btn btn-links flex justify-center cursor-pointer p-1" />
        ),
      },
      {
        id: 5,
        title: "Html & Css - Escola Espai - ",
        year: "2023",
        link: "https://espai.es/certificates/06674403XEstandaresWebHTML5CSS3.pdf",
        see: (
          <BsArrowUpRight className="btn btn-links flex justify-center cursor-pointer p-1" />
        ),
      },
      {
        id: 6,
        title: "Adobe Photoshop - Escola Espai - ",
        year: "2023",
        link: "https://espai.es/certificates/06674403XPhotoshopCC.pdf",
        see: (
          <BsArrowUpRight className="btn btn-links flex justify-center cursor-pointer p-1" />
        ),
      },
      {
        id: 7,
        title: "Adobe Illustrator - Escola Espai - ",
        year: "2023",
        link: "https://espai.es/certificates/06674403XIllustratorCC.pdf",
        see: (
          <BsArrowUpRight className="btn btn-links flex justify-center cursor-pointer p-1" />
        ),
      },
      {
        id: 8,
        title: "Adobe InDesign - Escola Espai - ",
        year: "2022",
        link: "https://espai.es/certificates/06674403XIndesignCC.pdf",
        see: (
          <BsArrowUpRight className="btn btn-links flex justify-center cursor-pointer p-1" />
        ),
      },
    ],
  },
];

const About = () => {
  const [index, setIndex] = useState(0);
  return (
    <section className="section md:mt-16 animationA" id="about">
      <div className="white-gradient5" />
      <div className="white-gradient6" />
      <div className="container mx-auto items-center">
        <div className="flex flex-col lg:flex-row mx-auto">
          {/* left side */}
          <article className=" about flex flex-col">
            <h2 className="h2 text-accent-3 pb-6">About me</h2>
            <div>
              <h3 className="text-white/70 text-xl mr-6 mb-4">
                Front-end developer specialized in{" "}
                <strong className="font-bold text-white">React Js</strong> and{" "}
                <strong className="font-bold text-white">Next Js </strong>
                With a good eye for design.
                <br />
                <br />
                I started with graphic and web design
                <br />
                cause I had always liked drawing since
                <br /> I was a child.
              </h3>
              <p className="pb-5 mb-7 mr-6 ">
                But later I realized that what really satisfies me, is being
                able to bring my designs to real life
                <br />
                And make those designs solve the needs of other people.
              </p>
            </div>

            <button className="btn-2 rounded-2xl glass mb-6 shadow-2xl">
              <div>
                <a
                  href="#work"
                  className="flex justify-center gap-2 items-center"
                >
                  <IoFolderOpen />
                  Portfolio
                </a>
              </div>
            </button>
          </article>
          {/* right side */}
          <div className="skills mx-auto items-center">
            <div className="flex skills-items justify-items justify-between w-full md:gap-10 gap-20 xl:gap-14 sm:gap-8 sm:w-full mx-auto xl:mx-0 mb-7 text-whitenpm text-5xl after:bg-accent">
              {aboutData.map((item, itemIndex) => {
                return (
                  <div
                    key={item.id}
                    className={`${
                      index === itemIndex &&
                      "text-accent-3 after:w-[100%] after:bg-accent  after:transition-all after-transition:duration-300"
                    }
                    cursor-pointer capitalize  xl:text-lg relative after:w-7
                    after:h-[2px] after:bg-white after:absolute  after:-bottom-1 after:flex 
                    after: left-0`}
                    onClick={() => setIndex(itemIndex)}
                  >
                    <div className="h3 mb-0 ">{item.title}</div>
                  </div>
                );
              })}
            </div>

            <div>
              {aboutData[index].info.map((item, itemIndex) => {
                const { link, title, role } = item;
                return (
                  <div className="mb-1 min-w[450px]" key={itemIndex}>
                    {/* years */}
                    <div className="text-white/85 glass py-2 min-w-[350px] px-3 backdrop-blur-2x1 rounded-lg flex flex-col rounded-lg hover:bg-white/10 transition-all duration-200 ease-in-out">
                      {/* credentials */}
                      <div className="flex  justify-between text-left p-2 gap-3 ">
                        <div>
                          <p className="font-bold text-white text-xl">{role}</p>
                          {title}

                          {item.year}
                        </div>
                        <div>
                          <a
                            href={link}
                            className="cursor-pointer"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.see}
                          </a>
                        </div>
                      </div>

                      <div
                        key={index.id}
                        className="text-left font-semibold px-3 text-white/50"
                      >
                        {item.stage}
                      </div>
                    </div>
                    {/* icons */}
                    <div className="text-white/90 mt-5 mb-5 h2 min-w-[350px] text-4xl flex gap-4">
                      {item.icons}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
