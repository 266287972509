import { React, useState } from "react";
// images
import image from "../assets/photo.webp";
import { IoMdCloudDownload } from "react-icons/io";
// icons social
import { FaGithub, FaLinkedin, FaBehance } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaCopy } from "react-icons/fa6";

// resume
import Resume from "../assets/imad.pdf";
// css
import "./Banner.css";
import "../index.css";
// type animation
import { TypeAnimation } from "react-type-animation";

import { CopyToClipboard } from "react-copy-to-clipboard";

const Banner = () => {
  const [show, setShow] = useState(false);
  const textToCopy = "imadniabou@gmail.com"; // The text you want to copy
  const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied
  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };

  return (
    <section
      className="min-h[88vh] lg:min-h-[77vh] flex items-center"
      id="home"
    >
      <div className="white-gradient" />
      <div className="white-gradient2" />
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
          {/* text */}
          <div className="flex-1 text-center font-secondary lg:text-left fadeInUp">
            <h1 className="text-accent-4 mb-4 text-[55px] font-primary leading-[.8] lg:text-[100px]">
              IMAD
              <span className="bg-gradient-to-r from-violet-800 via-purple-600 to-orange-200 text-transparent bg-clip-text animate-gradient">
                {" "}
                NIABOU
              </span>
            </h1>
            <div className="mb-6 text-[22px] lg:text-[42px] font-secondary font-semibold leading-[1]">
              <span className="text-white mr-4">I am a</span>
              <TypeAnimation
                sequence={["Front-End", 2000, "Developer", 2000]}
                speed={50}
                className="text-accent-3"
                wrapper="span"
                repeat={Infinity}
              />
            </div>
            <p className="mb-8 max-w-lg mx-auto lg:mx-0">
              Passionate about web design and development
              <br />
              Highly creative and imaginative.
            </p>
            <div className="flex btn-banner max-w-max gap-x-6 items-center mb-12 max-auto lg:mx-0">
              <div className="max-w-lg w-96 flex gap-5 mx-auto">
                <button className="btn mb-6 shadow-2xl">
                  <div>
                    <a
                      href="#contact"
                      className="flex justify-center gap-2 items-center "
                    >
                      <IoMdMail />
                      Contact
                    </a>
                  </div>
                </button>
                <button className="btn-2 glass shadow-2xl hover:text-white rounded-2xl ">
                  <div>
                    <a
                      href={Resume}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex justify-center gap-2 items-center"
                    >
                      <IoMdCloudDownload />
                      My C.V
                    </a>
                  </div>
                </button>
              </div>
            </div>

            {/* socials */}
            <div
              className="flex  text-[30px] gap-x-6 max-w-max mx-auto lg:mx-0"
              id="social"
            >
              <a
                href="https://www.linkedin.com/in/imad-niabou/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://github.com/ImadNiabou"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub />
              </a>
              <a
                href="https://www.behance.net/imadniabou1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaBehance />
              </a>
              <div className="flex gap-2 align-middle">
                <button onMouseEnter={() => setShow(!show)}>
                  {
                    <a
                      href="mailto:imadniabou@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoMdMail />
                    </a>
                  }
                </button>
                {show && (
                  <div className="flex gap-1">
                    <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
                      <button className="text-[16px] text-white/60">
                        <FaCopy className="hover:text-accent transition-all duration-300" />
                      </button>
                    </CopyToClipboard>
                    {copyStatus && (
                      <p className="text-sm text-accent-3 align-middle">
                        Mail copied!
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* image  */}
          <div className="fadeInDown">
            <div className=" box lg:w-95 lg:h-95 flex lg:items-center items-center overflow-hidden rounded-full">
              <img src={image} alt="img" className="rounded-full" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
