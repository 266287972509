import React from "react";
import logo from "../assets/logo.svg";
import { FaFileContract } from "react-icons/fa";

const Header = () => {
  return (
    <header className="py-8">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <a href="#banner">
            <img src={logo} alt="logo" />
          </a>
          {/* Button */}
          <button className="btn">
            <div>
              <a
                href="#contact"
                className="flex justify-center gap-2 items-center"
              >
                <FaFileContract />
                Hire me
              </a>
            </div>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
