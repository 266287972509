import React from "react";

// animation develop
import develop from "../../src/assets/animation/develop.json";
import Lottie from "lottie-react";
// countup
import CountUp from "react-countup";

import { BsArrowUpRight } from "react-icons/bs";

// services data
const services = [
  {
    name: "Front-End Development",
    description:
      "I'm a Front-end Developer building Websites and Applications that leads to the success of the overall product.",
    link: "https://www.imadniabou.com",
  },
  {
    name: "UX/UI Design",
    description:
      "Although I specialize more in web development. I feel good with UX/UI design tools especially with Figma.",
    link: "https://www.behance.net/gallery/160161881/Sushi-app",
  },
  {
    name: "Graphic Design",
    description:
      "Photoshop and Illustrator are two tools that I like. In some way Development and design are connected.",
    link: "https://www.behance.net/gallery/168597291/Ilustration-Of-The-Batman",
  },
];

const Services = () => {
  return (
    <section className="section animationA" id="services">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* imag & text */}
          <div className="s-left flex-1">
            {/* animation*/}
            <div className="animation flex items-center ">
              {/* animation develop */}
              <Lottie animationData={develop} />
            </div>
            {/* text */}
            <div className="text-white/70">
              <h2 className="h2 text-accent-3 mb-8">What i do</h2>
              <h3 className="h4 max-w-[455px] mb-7">
                I´m a{" "}
                <strong className="font-bold text-white">
                  Front-end Developer
                </strong>{" "}
                passionate about
                <br />
                web design and development
                <br />
                Highly creative and imaginative.
              </h3>
              <div className="flex gap-6 mb-10">
                <div className="text-accent-3 flex flex-col">
                  <span className="text-4xl font-extrabold">
                    <span>+</span>
                    <CountUp start={0} end={7} duration={20} />
                  </span>
                  <span className="text-[16px]">Projects</span>
                </div>
                <div className="text-accent-3 flex flex-col">
                  <span className="text-4xl font-extrabold">
                    <span>+</span>
                    <CountUp start={2} end={8} duration={20} />
                  </span>
                  <span className="text-[16px]">Tecnologies</span>
                </div>
              </div>
            </div>
          </div>

          {/* services list */}

          <div className="s-right">
            <h2 className="h2 text-accent-3 mb-8 sm:mb-14">Services</h2>
            {services.map((service, index) => {
              const { name, description, link } = service;
              return (
                <div
                  className="glass hover:bg-white/10 transition-all duration-200 ease-in-out rounded-xl p-3 mb-[38px] flex"
                  key={index}
                >
                  <div className="max-w-[476px]">
                    <h4 className="text-[22px] text-left px-3 tracking-wider font-primary font-semibold mb-5">
                      {name}
                    </h4>
                    <p className="font-secondary text-white/80 leading-tight text-left pb-3 px-3">
                      {description}
                    </p>
                  </div>
                  <div className="links flex flex-col flex-1 items-end">
                    <a
                      href={link}
                      className="btn btn-links mb-[42px] flex justify-center items-center p-0.5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsArrowUpRight />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
