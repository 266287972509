import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "./Work.css";

import data from "../utils/slider.json";
import { sliderSettings } from "../utils/Common";

// icons
import { FaGithub } from "react-icons/fa";
import { IoDesktopOutline } from "react-icons/io5";
// tabs
import { Tabs } from "flowbite-react";

const dataUx = [
  {
    image: "../salmon-sushi.webp",
    name: "Salmon Sushi",
    detail: "Figma, Photoshop, Illustrator",
    description:
      "Salmon Sushi is a restaurant that sells many varieties of sushi. It is inspired by fresh salmon.",
    link: "https://www.behance.net/gallery/160161881/Sushi-app",
  },
  {
    image: "../coffeShop.webp",
    name: "Coffe Shop",
    detail: "Figma, Photoshop, Illustrator",
    description:
      "I can't live without having a good glass of coffee every morning. and that's why I decided to make this design..",
    link: "https://www.behance.net/gallery/167471243/Coffeeshopp-UXUI-Design",
  },
  {
    image: "../wheather.webp",
    name: "Weather App",
    detail: "Figma, Photoshop",
    description:
      "This app has a design for both light mode and dark mode. It is inspired by the calm of nature. ",
    link: "https://www.behance.net/gallery/161922017/Weather-app",
  },
  {
    image: "../batman.webp",
    name: "The Batman",
    detail: "Illustrator",
    description:
      "This has been the final work of the Illustrator course. I decided to make this poster. Because Batman is my favorite superhero character.",
    link: "https://www.behance.net/gallery/168597291/Ilustration-Of-The-Batman",
  },
  {
    image: "../dragon.webp",
    detail: "Photoshop",
    name: "Dragon Warrior",
    description:
      "This has been the final project of the Photoshop course. I decided to make this poster. Because I love old Japan.",
    link: "https://www.behance.net/gallery/168593955/Dragon-Warrior",
  },
];

export const Work = () => {
  return (
    <section className="section animationA" id="work">
      <div className="white-gradient3 " />
      <div className="white-gradient4" />
      <div className="container  mx-auto">
        <div>
          <h2 className="h2 max-w-[455px] text-accent-3">My Projects</h2>
        </div>
        <article className="mx-auto overflow-hidden">
          <div>
            <div className="h3 font-secondary">Latest Works</div>
            <p>
              Here are some of my latest works
              <br />
              To see more works you can visit my Github and Behance profiles.
            </p>
          </div>

          <Tabs aria-label="Pills" style="pills" className="tabs">
            <Tabs.Item active title="Front-end">
              <div className="text-text-3 fadeInUp">
                <Swiper
                  className="text-white"
                  {...sliderSettings}
                  pagination={{
                    dynamicBullets: true,
                  }}
                >
                  <SliderButtons />
                  {data.map((card, i) => (
                    <SwiperSlide key={i}>
                      <div className="r-card relative  overflow-hidden items-center group group:hover:shadow">
                        <img
                          className=" group-hover:scale-125 transition-all duration-500 "
                          src={card.image}
                          alt="img"
                        />
                        <article className="p-6 text-secondary absolute inset-0 bg-gradient-to-r from-black  via-purple-950 to-black  opacity-0 group-hover:opacity-100 transition-all duration-00 ">
                          <div className="absolute bottom-0 translate-y-full group-hover:translate-y-10 group-hover:xl:-translate-y-6 transition-all duration-300 delay-80">
                            <div className="flex space-x-0 gap-4 mx-0 text-2xl group-hover:xl:-translate-y-4 transition-all duration-400 delay-90">
                              <a
                                href={card.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="translate-y-[500%] group-hover:translate-y-0 transition-all duration-300 delay-150"
                              >
                                <FaGithub className="hover:text-accent transition-all duration-600" />
                              </a>
                              <a
                                href={card.demo}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="translate-y-[500%] group-hover:translate-y-0 transition-all duration-300 delay-150"
                              >
                                <IoDesktopOutline className="hover:text-accent transition-all duration-600" />
                              </a>
                            </div>
                            <div className="mt-2 text-[18px] h2 tracking-[.2em]  delay-90 translate-y-[500%] group-hover:translate-y-0 transition-all duration-400 delay-200">
                              {card.name}
                            </div>
                            <div className="text-[13px] mb-[12px] text-white/80 tracking-[.2em]  delay-100 translate-y-[580%] group-hover:translate-y-0  transition-all duration-600 delay-400">
                              {card.description}
                            </div>
                            <div className=" details text-white/80 translate-y-[500%] mb-10 group-hover:translate-y-0 transition-all duration-300 delay-150 ">
                              {card.detail}
                            </div>
                          </div>
                        </article>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </Tabs.Item>
            <Tabs.Item title="UX|UI Design">
              <div className="fadeInUp">
                <Swiper
                  className="text-white"
                  {...sliderSettings}
                  pagination={{
                    dynamicBullets: true,
                  }}
                >
                  <SliderButtons />
                  {dataUx.map((card, i) => (
                    <SwiperSlide key={i}>
                      <div className="r-card  flex-col relative  overflow-hidden flex items-center justify-center group group:hover:shadow">
                        <img
                          className=" group-hover:scale-125 transition-all duration-500 "
                          src={card.image}
                          alt="img"
                        />
                        <article className="p-6 text-secondary absolute inset-0 bg-gradient-to-r from-black  via-purple-950 to-black  opacity-0 group-hover:opacity-100 transition-all duration-00 ">
                          <div className="absolute bottom-0 translate-y-full group-hover:translate-y-10 group-hover:xl:-translate-y-6 transition-all duration-300 delay-80">
                            <div className="flex space-x-0 gap-4  mx-0 text-2xl group-hover:xl:-translate-y-4 transition-all duration-400 delay-90">
                              <a
                                href={card.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="translate-y-[500%] group-hover:translate-y-0 transition-all duration-300 delay-150"
                              >
                                <IoDesktopOutline className="hover:text-accent transition-all duration-600" />
                              </a>
                            </div>
                            <div className="mt-2 text-[18px] h2 tracking-[.2em]  delay-90 translate-y-[500%] group-hover:translate-y-0 transition-all duration-400 delay-200">
                              {card.name}
                            </div>
                            <div className="text-[13px] mb-[12px] text-white/80 tracking-[.2em]  delay-100 translate-y-[580%] group-hover:translate-y-0  transition-all duration-600 delay-400">
                              {card.description}
                            </div>
                            <div className=" details text-white/80 translate-y-[500%] mb-10 group-hover:translate-y-0 transition-all duration-300 delay-150 ">
                              {card.detail}
                            </div>
                          </div>
                        </article>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </Tabs.Item>
          </Tabs>
        </article>
      </div>
    </section>
  );
};

export default Work;

// Buttons Works
const SliderButtons = () => {
  const swiper = useSwiper();
  return (
    <div className="flex r-buttons fadeInDown">
      <button className="btn-2 glass" onClick={() => swiper.slidePrev()}>
        &lt;
      </button>
      <button onClick={() => swiper.slideNext()}>&gt;</button>
    </div>
  );
};
